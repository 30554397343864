export const messages = {
    requiredSignInField: 'Please fill all the required fields.'
}

export const apiUrl = {
    //baseApiUrl: 'https://beanstalktest.askmonty.com.au',
    baseApiUrl: 'https://beanstalk.askmonty.com.au',
    // baseApiUrl: 'https://a127-2409-4054-110-181-ef90-3dfb-6faa-9f79.ngrok-free.app',
    // baseApiUrl: 'https://76a6-2405-205-10c-145-1d4b-e31f-7e68-5b80.ngrok-free.app',
    // baseOdooApiUrl: 'https://a0a3-2405-201-5509-e0dd-aa4-8289-f7d8-9f6f.ngrok-free.app',
    //baseOdooApiUrl: 'https://test.askmonty.com.au',
    baseOdooApiUrl: 'https://erp.askmonty.com.au',
    userLogin: '/login',
    userLogout: '/userlogout',
    palletStatus: '/getpalletstatus',
    palletStoreStatus: '/getstorestatus',
    palletWarehouseStatus: '/getwarehousestatus',
    palletCategory: '/getpalletcategories',
    palletStore: '/getstores',
    pallets: '/getitems',
    palletItemsById: '/getpalletitemsbyid?id={0}',
    palletTypes: '/getpallettypes',
    palletBuilders: '/getbuilders',
    addPallet: '/addpallet',
    updatePallet: '/updatepallet',
    updatePalletOdoo: '/updateitopalletinfo',
    addPalletItem: '/addpalletitem',
    deletePallet: '/deletepallet?id={0}',
    deletePalletItem: '/deletepalletitem?id={0}',
    updatePalletItem: '/updatepalletitem',
    palletShipper: '/getshippers',
    palletByStatus: '/getpalletbystatus',
    updatePalletShippingStatus: '/updatepalletshippingstatus',
    addPalletAndItems: '/addupdatepalletanditem',
    onlineOrders: '/getonlineorders',
    getDescription: '/productCartonQuantity?barcode={0}',

    getDIDdescription: '/diddescription?did_reference={0}&barcode={1}',
    getITOdescription_new: '/itodescription?ito_reference={0}&barcode={1}',
    
    getDiDnumbervalid: '/didnumbervalid?did_number={0}&store_id={1}',
    getITOnumbervalid: '/itonumbervalid?ito_number={0}',
    
    getStorePO: '/get_pallet_po?store_id={0}',

    getProductQuantityDetails: '/productQuantityDetails?barcode={0}&warehouse_id={1}',
    getITODetails: '/getITODetails',
    syncPrice: '/syncprice',
    syncPriceStatus: '/getsyncpricestatus',
    onlineOrdersCost: '/getonlineorderscost',
    products: '/getproductsnew',
    productType: '/getproducttype',
    updateProduct: '/updateProductsPublishedStatus',
    updateProductDescription: '/updateProductDescription',
    syncProducts: '/updateProductImages',
    addUpdateShipper: '/addupdateshipper',
    deleteShipper: '/deleteshipper?shipper_id={0}',
    addUpdatePalletType: '/addupdatepallettypes',
    deletePalletType: '/deletepallettype?id={0}',
    appUsers: '/getusers',
    addUpdateUsers: '/addupdateusers',
    deleteUser: '/deleteuser?id={0}',
    addUpdateCategory: '/addupdatepalletcategories',
    deleteCategory: '/deletepalletcategories?category_id={0}',
    getUserType: '/getuserstype',
    getPO: '/getpoanditems',
    getPOStatus: '/rexgetreceivestatusid',
    poItemsById: '/getpoitemsbyid?id={0}',
    getIto:'/getito?ITOID={0}',
    updateQty:'/updatecartonqty',
    getPoid: '/getpoid?poid={0}',
    getpo: '/get_po_data?poId={0}',
    getpodetails: '/get_po_details?poId={0}',
    validatepo: '/get_validate_po?poId={0}',
    updateqty: '/update_qty_to_receive?orderline_id={0}&qty_to_receive={1}',
    //getpo: '/en_AU/get_po_data?poId={0}',
    addPoItems: '/addpoitems',
    getPOItemQuantity: '/getpoitemquantity?poid={0}&SupplierSku={1}',
    receivePO: '/rexreceivepo',
    receivePOnew: '/rexreceiveponew',
    uploadPO: '/rexuploadpo',
    getPOStatusAndItemCount: '/rexgetpostatusanditemcount?id={0}',
}

export const webUrl = {
    signIn: '/signin',
    pallet: '/pallet',
    palletnew: '/palletnew',
    addUpdatePallet: "/addupdatepallet",
    palletBooking: "/palletbooking",
    palletDispatch: "/palletdispatch",
    distribution: "/distribution",
    warehouse: '/warehouse',
    estore: '/estore',
    product: '/product',
    //linodeObjectUrl: 'https://mate4tech.us-east-1.linodeobjects.com/',
    linodeObjectUrl: 'https://aria-images.us-east-1.linodeobjects.com/',
    shipper: '/shipper',
    palletType: '/pallettype',
    user: '/user',
    category: '/category',
    purchaseOrder: '/purchaseorder',
    receivePO: '/receivepo',
    receivePONew: '/receiveponew',
    receivePONewodoo: '/receiveponewodoo'
}

export const config = {
    BRISBANE_TIME_ZONE: 'Australia/Brisbane'
}
